<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }} | {{ newClients.length }}
          </h3>
        </div>
        <!-- <div class="col text-right">
          <base-button type="primary" v-on:click="newClient"
            >Agregar</base-button
          >
        </div> -->
      </div>
    </div>

    <div class="table-responsive" style="min-height: 250px">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="newClients"
      >
        <template slot="columns">
          <th>Nombre</th>
          <th>Clave</th>
          <th>Dirección</th>
          <th>Agente</th>
          <th>Contacto</th>
          <th>Estatus</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img
                  alt="Imagen usuario"
                  src="img/theme/user-profile-default.jpg"
                />
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.name }}</span>
              </div>
            </div>
          </th>
          <td>
            {{ row.key }}
          </td>
          <td>{{ row.street }} {{ row.numExt }} {{ row.address }}</td>
          <td>
            {{ row.agent }}
          </td>
          <td>
            {{ row.contact1 }}
          </td>
          <td class="budget">
            <badge
              class="badge-dot mr-4"
              :type="convertStatusToColor(row.status)"
            >
              <i :class="`bg-${convertStatusToColor(row.status)}`"></i>
              <span class="status">{{ convertStatusToName(row.status) }}</span>
            </badge>
          </td>
        </template>
      </base-table>
    </div>
    
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination @input="changePage" :value="page" :pageCount="pageCount" :perPage="perPage"></base-pagination>
    </div>
  </div>
</template>
<script>
import httpMixin from "../../mixins/httpMixin";
export default {
  name: "new-clients-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      newClients: [],
      page: 0,
      perPage: 0,
      pageCount: 0,
    };
  },
  mounted() {
    this.fetchNewClientsInfo();
  },
  methods: {
    fetchNewClientsInfo: function () {
      this.httpGet("/clients" + `?page=${this.page}`)
        .then((res) => {
          this.newClients = res.data.data;
          this.perPage = res.data.per_page;
          this.pageCount = res.data.last_page;
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    convertStatusToColor(status) {
      if (status == "S") {
        return "success";
      } else if (status == "P") {
        return "warning";
      } else if (status == "E") {
        return "danger";
      }
    },
    convertStatusToName(status) {
      if (status == "S") {
        return "Guardado";
      } else if (status == "P") {
        return "Pendiente";
      } else if (status == "E") {
        return "Error";
      }
    },
    changePage: function (value) {
      this.page = value;
      this.fetchNewClientsInfo();
    },
    // updateStatus: async function (customer, status) {
    //   customer.status = status;
    //   this.httpPut("/clients", customer)
    //     .then((res) => {
    //       console.log(customer);
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  mixins: [httpMixin],
};
</script>
<style></style>
