<template>
    <div>
        <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8">
            
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <new-clients-table title="Lista de clientes"></new-clients-table>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import NewClientsTable from './Tables/NewClientsTable'
  export default {
    name: 'newClients',
    components: {
      NewClientsTable
    }
  };
</script>
<style></style>
